.react-tel-input {
  height: 40px !important;
  width: 100% !important;
}
.react-tel-input .form-control {
  height: 40px !important;
  border-color: #e2e8f0 !important;
  border-radius: 0.375em !important;
  width: 100% !important;
  padding-left: 0.5rem !important;
  padding: 0.5rem 1rem !important;
}

/* Menu carrot arrow */
.custom_menu::before,
.custom_menu::after {
  position: absolute;
  left: calc(50% - 10px);
  top: -5px;
  width: 0;
  height: 0;
  content: '';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
}
.custom_menu:before {
  border-bottom-color: inherit;
  margin-left: 0px;
  margin-top: -1px;
}

/* NProgress Circular Spinner Removed From UI */

#nprogress .spinner {
  display: none !important;
}
